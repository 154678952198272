import { Provider } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import store from "../redux/store";
import "../styles/global.scss";
import "../styles/index.scss";
import "../styles/header.scss";
import "../styles/auth.scss";
import "../styles/product.scss";
import "../styles/search.scss";
import "../styles/account.scss";
import "../styles/cart.scss";
import "../styles/track.scss";
import { Toaster } from "react-hot-toast";
import Axios from "axios";

const debug = process.env.NODE_ENV === "development";
Axios.defaults.baseURL = debug && false ? "https://localhost:5001" : "https://api-main.dealsonopenbox.com";

function MyApp({ Component, pageProps }) {
	return (
		<>
			<Provider store={store}>
				<Component {...pageProps} />
				<Toaster />
			</Provider>
		</>
	);
}

// initialize store and wrapper store
const makeStore = () => store;
const wrapper = createWrapper(makeStore);
export default wrapper.withRedux(MyApp);
